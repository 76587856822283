import { render, staticRenderFns } from "./Consultations.vue?vue&type=template&id=b50ee28a&scoped=true"
import script from "./Consultations.vue?vue&type=script&lang=js"
export * from "./Consultations.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b50ee28a",
  null
  
)

export default component.exports