<template>
  <div class="w-100 pt-4">
    <AsyncList
      :is-all-items-loaded="isAllItemsLoaded"
      without-loader
      class="h-100"
      @load-more-items="loadMoreItems"
    >
      <b-container fluid="xl">
        <div class="mb-3">
          <div class="h3 mb-0">
            Консультации
          </div>
        </div>

        <ConsultationsSearchPanelNew
          v-model="filter"
          @search="consultationsFetch(true)"
        />

        <div class="mt-4">
          <div
            v-if="!totalCount && isAllItemsLoaded"
            class="w-100 bg-white border rounded p-4 font-weight-bold"
          >
            Ничего не найдено по выбранным фильтрам
            <span
              style="cursor: pointer;"
              class="text-primary underlineAnnotation"
              @click="clearAndFetch"
            >
              Сбросить фильтры
            </span>
          </div>

          <template v-if="consultations.length">
            <div
              v-for="consultation in consultations"
              :key="consultation.id"
            >
              <ConsultationInfoNew
                :consultation="consultation"
                class="mt-3"
              />
            </div>
          </template>

          <div v-if="isLoading">
            <b-col
              v-for="item in 20"
              :key="item"
              cols="12"
              class="mt-3 p-0 rounded"
            >
              <b-skeleton-img
                no-aspect
                height="183px"
              />
            </b-col>
          </div>
        </div>
      </b-container>
    </AsyncList>
  </div>
</template>

<script>
import { formatISO } from '@evd3v/date-fns';
import Bus from '@/eventBus';
import { dateWithoutTime } from '@/helpers/utils';
import AsyncList from '@/components/AsyncList';
import ConsultationsSearchPanelNew from '@/components/Consultations/ConsultationsSearchPanelNew';

export default {
  name: 'ConsultationsNew',
  page: {
    title: 'CRM Doctis - Консультации',
  },
  components: {
    AsyncList,
    ConsultationInfoNew: () => import('@/components/Consultations/ConsultationInfo'),
    ConsultationsSearchPanelNew,
  },
  props: {
    status: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isAllItemsLoaded: false,
      isLoading: false,
      limit: 15,
      filter: {
        doctorName: null,
        patientName: null,
        phoneNumber: null,
        consultationId: null,
        startCreateDate: null,
        endCreateDate: null,
        startSlotDate: null,
        endSlotDate: null,
        startCloseDate: null,
        endCloseDate: null,
        status: null,
        type: null,
        clinicId: null,
        doctorId: null,
        specializationId: null,
      },
    };
  },
  computed: {
    consultations() {
      return this.$store.state.Consultation.consultations;
    },
    totalCount() {
      return this.consultations.length;
    },
  },
  watch: {
    filter: {
      handler(filter) {
        const {
          startCreateDate,
          endCreateDate,
          startSlotDate,
          endSlotDate,
          startCloseDate,
          endCloseDate,
        } = filter;

        localStorage.setItem('consultation-filter', JSON.stringify({
          ...this.filter,
          startCreateDate: startCreateDate ? dateWithoutTime(formatISO(startCreateDate)) : null,
          endCreateDate: endCreateDate ? dateWithoutTime(formatISO(endCreateDate)) : null,
          startSlotDate: startSlotDate ? dateWithoutTime(formatISO(startSlotDate)) : null,
          endSlotDate: endSlotDate ? dateWithoutTime(formatISO(endSlotDate)) : null,
          startCloseDate: startCloseDate ? dateWithoutTime(formatISO(startCloseDate)) : null,
          endCloseDate: endCloseDate ? dateWithoutTime(formatISO(endCloseDate)) : null,
        }));
      },
      deep: true,
    },
  },
  created() {
    Bus.$on('consultations:update', this.updateConsultations);
    Bus.$on('consultations:reset-all-filters', this.resetAllFilters);
    Bus.$on('consultations:reset-popover-filters', this.resetPopoverFilters);

    if (localStorage.getItem('consultation-filter')) {
      try {
        const filter = JSON.parse(localStorage.getItem('consultation-filter'));
        const {
          startCreateDate,
          endCreateDate,
          startSlotDate,
          endSlotDate,
          startCloseDate,
          endCloseDate,
        } = filter;
        this.filter = {
          ...filter,
          startCreateDate: startCreateDate ? new Date(startCreateDate) : null,
          endCreateDate: endCreateDate ? new Date(endCreateDate) : null,
          startSlotDate: startSlotDate ? new Date(startSlotDate) : null,
          endSlotDate: endSlotDate ? new Date(endSlotDate) : null,
          startCloseDate: startCloseDate ? new Date(startCloseDate) : null,
          endCloseDate: endCloseDate ? new Date(endCloseDate) : null,
        };
      } catch (e) {
        console.error(e);
        localStorage.removeItem('consultation-filter');
      }
    }
  },
  beforeDestroy() {
    Bus.$off('consultations:update', this.updateConsultations);
    Bus.$off('consultations:reset-all-filters', this.resetAllFilters);
    Bus.$off('consultations:reset-popover-filters', this.resetPopoverFilters);
    this.$store.commit(this.$types.CONSULTATIONS_SET, []);
  },
  methods: {
    async consultationsFetch(clear) {
      this.isAllItemsLoaded = false;
      this.isLoading = true;

      const prevTotal = clear ? 0 : this.totalCount;

      try {
        const {
          startCreateDate,
          endCreateDate,
          startSlotDate,
          endSlotDate,
          startCloseDate,
          endCloseDate,
          consultationId,
        } = this.filter;

        await this.$store.dispatch(this.$types.CONSULTATIONS_NEW_SEARCH, {
          skip: prevTotal,
          take: this.limit,
          filter: {
            ...this.filter,
            consultationId: consultationId?.replaceAll(' ', ''),
            startCreateDate: startCreateDate ? dateWithoutTime(formatISO(startCreateDate)) : null,
            endCreateDate: endCreateDate ? dateWithoutTime(formatISO(endCreateDate)) : null,
            startSlotDate: startSlotDate ? dateWithoutTime(formatISO(startSlotDate)) : null,
            endSlotDate: endSlotDate ? dateWithoutTime(formatISO(endSlotDate)) : null,
            startCloseDate: startCloseDate ? dateWithoutTime(formatISO(startCloseDate)) : null,
            endCloseDate: endCloseDate ? dateWithoutTime(formatISO(endCloseDate)) : null,
          },
          clear: !!clear,
          save: true,
        });

        if (prevTotal + this.limit > this.totalCount) {
          this.isAllItemsLoaded = true;
        }
      } catch (e) {
        console.warn(e);
        this.isAllItemsLoaded = true;
      } finally {
        this.isLoading = false;
      }
    },
    loadMoreItems() {
      if (!this.isLoading) {
        this.consultationsFetch(false);
      }
    },
    updateConsultations(id) {
      if (this.consultations.find((consultations) => consultations.id === id)) {
        this.consultationsFetch(true);
      }
    },
    resetPopoverFilters() {
      localStorage.removeItem('consultation-filter');

      this.filter = {
        ...this.filter,
        startCreateDate: null,
        endCreateDate: null,
        startSlotDate: null,
        endSlotDate: null,
        startCloseDate: null,
        endCloseDate: null,
        status: null,
        clinicId: null,
        specializationId: null,
      };
    },
    resetAllFilters() {
      localStorage.removeItem('consultation-filter');

      this.filter = {
        doctorName: null,
        patientName: null,
        phoneNumber: null,
        consultationId: null,
        startCreateDate: null,
        endCreateDate: null,
        startSlotDate: null,
        endSlotDate: null,
        startCloseDate: null,
        endCloseDate: null,
        status: null,
        clinicId: null,
        specializationId: null,
      };
      this.$store.commit(this.$types.CONSULTATIONS_SET, []);
    },
    async clearAndFetch() {
      this.resetAllFilters();
      await this.consultationsFetch(true);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
